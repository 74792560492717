import { useContext, useMemo } from "react";
import {
  ACCESS_TYPE_FORZATO,
  ACCESS_TYPE_LIBERO,
  LEARNING_SYSTEM_MOODLE,
  LEARNING_TYPE_AUTONOMI_CORSI,
  LEARNING_TYPE_SOLO_AUTONOMO,
  LEARNING_TYPE_SOLO_CORSI,
  ROLE_STUDENT,
  ROLE_TEACHER,
} from "../utils/constants";
import { useActivationLink } from "../hooks/useQueryCustom";
import UserContext from "../context/user";
import Icon from "./icon";
import { toast } from "react-toastify";
type Props = {
  myProduct: MyProduct;
  selectProduct?: (myProduct: MyProduct) => void;
  handleCreateCourse?: (myProduct: MyProduct) => void;
  handleEnrollCourse?: (myProduct: MyProduct) => void;
};

const MyProductActions = ({
  myProduct,
  selectProduct,
  handleCreateCourse,
  handleEnrollCourse,
}: Props) => {
  const { roleName: userRole, product, courses, trackLinks } = myProduct;
  const { isActivationLinkClicked, isHelpLinkClicked, accessLinkClickCount } = trackLinks ?? {};

  const showHelpLink = useMemo(() => {
    return !isHelpLinkClicked && accessLinkClickCount < 4;
  }, [isHelpLinkClicked, accessLinkClickCount]);

  /*  const linkId = useMemo(() => {
    return product?.activationLink?.match(/joincourse\/(.*)\/offer/)?.[1] || null;
  }, [product]); */

  const { checkIESSession, logout } = useContext(UserContext);
  const activationLink = useActivationLink();

  const setActivationLink = async (isHelp: boolean) => {
    if (checkIESSession()) {
      const payload = {
        productId: myProduct.product.productId,
        isHelpLinkClicked: isHelp,
      };
      const data = await activationLink.mutateAsync(payload);
      //console.log(data);
      if (
        (data?.statusMessage === "Added record on DB" ||
          data?.statusMessage === "Recorded link click events") &&
        product.activationLink
      ) {
        const linkId = product.activationLink.match(/joincourse\/(.*)\/offer/)?.[1];
        if (linkId && !isHelp) {
          window.open(`https://console.pearson.com/student/offers/${linkId}`, "_blank");
        }
        window.location.href = product.activationLink;
      } else {
        toast.error("Errore durante l'attivazione del prodotto");
      }
    } else {
      logout();
    }
  };

  const access = async () => {
    if (checkIESSession()) {
      if (accessLinkClickCount < 4 && !isHelpLinkClicked) {
        const payload = {
          productId: myProduct.product.productId,
          accessLinkClickCount: 1,
        };
        await activationLink.mutateAsync(payload);
      }
      if (product.accessLink) {
        window.open(product.accessLink, "_blank");
      }
    } else {
      logout();
    }
  };

  return (
    <>
      {product?.typeElearningSystem !== LEARNING_SYSTEM_MOODLE ? (
        <div className="my-product__activation-group">
          {product?.activationLink && !isActivationLinkClicked && (
            <button onClick={() => setActivationLink(false)} className="button button--secondary">
              Attiva il prodotto
            </button>
          )}
          {product?.accessLink && (isActivationLinkClicked || !product?.activationLink) && (
            <>
              {showHelpLink && (
                <span className="my-product__activation-help">
                  <Icon name="question-circle" /> se riscontri problemi di accesso al libro (Error
                  code: ETEXT001) -{" "}
                  <button onClick={() => setActivationLink(true)}>
                    <strong>fai clic qui</strong>
                  </button>
                </span>
              )}
              <button onClick={() => access()} className="button button--secondary">
                Accedi
              </button>
            </>
          )}
        </div>
      ) : (
        <>
          {userRole === ROLE_TEACHER && (
            <div className="row">
              {product?.accessType === ACCESS_TYPE_LIBERO ? (
                <div className="d-flex mt-auto">
                  {(product.learningType === LEARNING_TYPE_SOLO_CORSI ||
                    product.learningType === LEARNING_TYPE_AUTONOMI_CORSI) && (
                    <>
                      {courses?.length > 0 && (
                        <button
                          onClick={() => selectProduct && selectProduct(myProduct)}
                          className="my-product__button button button--secondary me-2"
                        >
                          Visualizza tutti i corsi
                        </button>
                      )}
                      {!product.defaultProduct && (
                        <button
                          onClick={() => handleCreateCourse && handleCreateCourse(myProduct)}
                          className="my-product__button button button--secondary"
                        >
                          Crea il corso
                        </button>
                      )}
                    </>
                  )}
                </div>
              ) : null}
              {product?.accessType === ACCESS_TYPE_FORZATO ? (
                <div className="d-flex mt-auto">
                  {product?.learningType === LEARNING_TYPE_SOLO_CORSI && courses?.length > 0 && (
                    <>
                      <a
                        href={courses[0]?.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="button button--secondary"
                      >
                        Vai al corso
                      </a>
                    </>
                  )}
                  {product?.learningType === LEARNING_TYPE_SOLO_AUTONOMO ? (
                    <div className="d-flex mt-auto">
                      <button
                        onClick={() => window.open(product?.link, "_blank")}
                        className="button button--secondary"
                      >
                        Accedi
                      </button>
                    </div>
                  ) : null}
                </div>
              ) : null}
            </div>
          )}
          {userRole === ROLE_STUDENT && (
            <div className="row">
              <div className="d-flex mt-auto">
                {courses?.length > 0 ? (
                  <>
                    {courses[0].isIndipendent ? (
                      <a
                        href={courses[0].url}
                        className="my-product__button button button--secondary me-2"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Studio autonomo
                      </a>
                    ) : (
                      <a
                        href={courses[0]?.url}
                        className="my-product__button button button--secondary me-2"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Vai al corso
                      </a>
                    )}
                    {!product.defaultProduct &&
                      product.learningType !== LEARNING_TYPE_SOLO_AUTONOMO &&
                      product.accessType !== ACCESS_TYPE_FORZATO &&
                      courses[0] && (
                        <button
                          onClick={() => handleEnrollCourse && handleEnrollCourse(myProduct)}
                          className="my-product__button button button--secondary"
                        >
                          Cambia corso
                        </button>
                      )}
                  </>
                ) : (
                  <>
                    {product?.moodleId > 0 ? (
                      <>
                        {product?.learningType === LEARNING_TYPE_SOLO_AUTONOMO ||
                        product?.learningType === LEARNING_TYPE_AUTONOMI_CORSI ? (
                          <button
                            onClick={() => handleEnrollCourse && handleEnrollCourse(myProduct)}
                            className="my-product__button button button--secondary"
                          >
                            Studio autonomo
                          </button>
                        ) : (
                          <button
                            onClick={() => handleEnrollCourse && handleEnrollCourse(myProduct)}
                            className="my-product__button button button--secondary"
                          >
                            Iscriviti a un corso
                          </button>
                        )}
                      </>
                    ) : (
                      <em>Corso non ancora disponibile</em>
                    )}
                  </>
                )}
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default MyProductActions;
