import { FieldErrors, UseFormRegister } from "react-hook-form";

type Props = {
  type?: "text" | "number" | "hidden";
  name: string;
  label?: string;
  pattern?: string;
  placeholder?: string;
  register: UseFormRegister<any>;
  errors?: FieldErrors;
  required?: boolean;
};
const FormInput = ({
  type = "text",
  name,
  label,
  pattern,
  placeholder,
  register,
  required = false,
  errors,
}: Props) => {
  return (
    <>
      <label htmlFor={name} className="form__label">
        {label}
      </label>
      <input
        type={type}
        id={name}
        pattern={pattern}
        className="form__input"
        {...register(name, { required: required })}
        placeholder={placeholder}
      />
      {errors && errors[name]?.type === "required" && (
        <p className="form__error" role="alert">
          Questo campo è richiesto
        </p>
      )}
    </>
  );
};

export default FormInput;
