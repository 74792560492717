import Skeleton from "react-loading-skeleton";
import { Fragment } from "react";
import MyProductActions from "./my-product-actions";

type Props = {
  myProduct: MyProduct;
  selectProduct: (myProduct: MyProduct) => void;
  handleCreateCourse: (myProduct: MyProduct) => void;
  handleEnrollCourse: (myProduct: MyProduct) => void;
};

const MyProduct = ({ myProduct, selectProduct, handleCreateCourse, handleEnrollCourse }: Props) => {
  const product = myProduct?.product;

  return (
    <div className="my-product">
      <div className="d-flex">
        <div className="my-product__image">
          {product?.coverImage ? (
            <img src={product?.coverImage} alt={product?.productName} />
          ) : (
            <Skeleton height={180} width={130} />
          )}
          {product?.tags ? <div className="card__tag">{product?.tags[0]?.tagName}</div> : null}
        </div>
        <div className="my-product__content d-flex flex-column w-100 ms-4">
          <h3 className="my-product__title">{product?.productName || <Skeleton />}</h3>
          {product?.productName ? (
            <Fragment>
              {product?.productSubtitle && (
                <h4 className="my-product__subtitle">{product.productSubtitle}</h4>
              )}
              <div className="my-product__edition">{product?.descriptionEdition}</div>
              <div className="my-product__author">
                {product?.author
                  ? product?.author?.map((author: Author, i: number) => (
                      <Fragment key={i}>
                        {author.authorName} {author.authorSurname}
                        {i < product.author.length - 1 ? <span> - </span> : ""}
                      </Fragment>
                    ))
                  : null}
              </div>
            </Fragment>
          ) : (
            <Skeleton count={3.5} />
          )}
        </div>
      </div>
      <div className="my-product__actions d-flex justify-content-end">
        <MyProductActions
          myProduct={myProduct}
          selectProduct={selectProduct}
          handleCreateCourse={handleCreateCourse}
          handleEnrollCourse={handleEnrollCourse}
        />
      </div>
    </div>
  );
};

export default MyProduct;
