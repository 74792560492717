import { callApiQl } from "../utils/call-api";
import { gql } from "graphql-request";
import { FRAGMENT_PRODUCT, FRAGMENT_PRODUCT_CARD_AND_SLIDE, FRAGMENT_COURSE } from "./fragments";
import { GROUP_ID } from "./search";
import { ROLE_STUDENT } from "./constants";

export const getProduct = async (operationName: string, productId: string, forceMock?: boolean) => {
  //console.log("getProduct query", productId);

  if (productId.length === 13) {
    const variables = { isbn: productId };
    const data = await callApiQl(
      gql`
          query ${operationName}($isbn: String!) {
            pearsonCheckIsbnProduct(isbn: $isbn,  groupId: ${GROUP_ID}) {
                ...ProductDetail
              }
          } ${FRAGMENT_PRODUCT}
        `,
      variables,
      forceMock || false
    );
    return data;
  } else {
    const variables = { productId };
    const data = await callApiQl(
      gql`
          query ${operationName}($productId: Long!) {
            pearsonProductById(productId: $productId) {
                ...ProductDetail
              }
          } ${FRAGMENT_PRODUCT}
        `,
      variables,
      forceMock || false
    );
    return data;
  }
};

export const getImportantProducts = async (key: any) => {
  const { queryKey, pageParam = 1 } = key;
  const [operationName, type, size] = queryKey;
  const productfilter = type ? `and productCategoryName eq '${type}'` : "";
  const filter = `important eq true ${productfilter}`;
  const data = await callApiQl(
    gql`
        query ${operationName}  {
          pearsonProducts(
            filter: "${filter}"
            groupId: ${GROUP_ID}
            page: ${pageParam}
            pageSize: ${size}
            sort: "publishDate:desc"
          ) {
            items {
              ...ProductDetail
            }
            page
            pageSize
            totalCount
          }
        } ${FRAGMENT_PRODUCT_CARD_AND_SLIDE} 
      `,
    null,
    false
  );

  return data;
};

export const getNewProdcut = async (key: any) => {
  const { queryKey, pageParam = 1 } = key;
  const [operationName, type, size] = queryKey;
  const productfilter = type ? `and productCategoryName eq '${type}'` : "";
  const filter = `newProduct eq true ${productfilter}`;
  const data = await callApiQl(
    gql`
        query ${operationName}  {
          pearsonProducts(
            filter: "${filter}"
            groupId: ${GROUP_ID}
            page: ${pageParam}
            pageSize: ${size}
            sort: "publishDate:desc"
          ) {
            items {
              ...ProductDetail
            }
            page
            pageSize
            totalCount
          }
        } ${FRAGMENT_PRODUCT_CARD_AND_SLIDE} 
      `,
    null,
    false
  );

  return data;
};

export const getProdutcsByTagAndCategories = async (key: any) => {
  const { queryKey, pageParam = 1 } = key;
  const [operationName, tagname, type, size] = queryKey;

  const filter = [tagname && `tagName eq '${tagname}'`, type && `productCategoryName eq '${type}'`]
    .filter(Boolean)
    .join(" and ");

  const data = await callApiQl(
    gql`
        query ${operationName}  {
          pearsonProducts(
            filter: "${filter}"
            groupId: ${GROUP_ID}
            page: ${pageParam}
            pageSize: ${size}
            sort: "publishDate:desc"
          ) {
            items {
              ...ProductDetail
            }
            page
            pageSize
            totalCount
          }
        } ${FRAGMENT_PRODUCT_CARD_AND_SLIDE} 
      `,
    null,
    false
  );

  return data;
};

export const getProductsByUserId = async (key: any) => {
  const { queryKey, pageParam = 1 } = key;
  const [operationName, userData, category, size = 6] = queryKey;
  const { userId, userIdImp, userRole } = userData;

  const variables = {
    userId,
    userIdImp,
    userRole: category === "Prodotto" && userRole === ROLE_STUDENT ? userRole : null,
    productCategoryName: category,
    page: pageParam,
    pageSize: size,
  };

  const data = await callApiQl(
    gql`
        query ${operationName} ($userId: String!, $userIdImp: String, $userRole: String, $pageSize: Int!, $productCategoryName: String!, $page: Int! ) {
          pearsonProductsByUserId(userId: $userId, groupId: ${GROUP_ID}, userIdImp: $userIdImp, userRole: $userRole, productCategoryName: $productCategoryName, pageSize: $pageSize, page: $page ) {
            items {
              courses {
                ...CourseDetail
              }
              product {
                ...ProductDetail
              }
              trackLinks {
                isActivationLinkClicked,
                isHelpLinkClicked,
                accessLinkClickCount
              }

              roleName
              userId
            }
            lastPage
            page
            pageSize
            totalCount
          }
        } ${FRAGMENT_PRODUCT} ${FRAGMENT_COURSE}
      `,
    variables,
    false
  );
  return data;
};

export const getHomeProductsByUserId = async (
  operationName: string,
  userData: UserDataForQuery,
  size: number = 2
) => {
  console.log("getHomeProductsByUserId!!!", userData);
  const { userId, userIdImp, userRole } = userData;

  const variables = {
    userId,
    userIdImp,
    userRole: userRole === ROLE_STUDENT ? userRole : null,
    pageSize: size,
  };

  const data = await callApiQl(
    gql`
        query ${operationName} ($userId: String!, $userIdImp: String, $userRole: String, $pageSize: Int!) {
          prodotti: pearsonProductsByUserId(
            userId: $userId, 
            groupId: ${GROUP_ID}, 
            userIdImp: $userIdImp, 
            userRole: $userRole, 
            productCategoryName: "Prodotto", 
            pageSize: $pageSize ) 
          {
            items {
              courses {
                ...CourseDetail
              }
              product {
                ...ProductDetail
              }
              trackLinks {
                isActivationLinkClicked,
                isHelpLinkClicked,
                accessLinkClickCount
              }

              roleName
              userId
            }
            lastPage
            page
            pageSize
            totalCount
          }
          webinar: pearsonProductsByUserId(
            userId: $userId, 
            groupId: ${GROUP_ID}, 
            userIdImp: $userIdImp, 
            productCategoryName: "Webinar", 
            pageSize: $pageSize ) 
          {
            items {
              courses {
                ...CourseDetail
              }
              product {
                ...ProductDetail
              }
              roleName
              userId
            }
            lastPage
            page
            pageSize
            totalCount
          }
          formazione: pearsonProductsByUserId(
            userId: $userId, 
            groupId: ${GROUP_ID}, 
            userIdImp: $userIdImp, 
            productCategoryName: "Formazione", 
            pageSize: $pageSize ) 
          {
            items {
              courses {
                ...CourseDetail
              }
              product {
                ...ProductDetail
              }
              roleName
              userId
            }
            lastPage
            page
            pageSize
            totalCount
          }
        } ${FRAGMENT_PRODUCT} ${FRAGMENT_COURSE}
      `,
    variables,
    false
  );
  return data;
};

export const addCourse = async (
  userData: UserDataForQuery,
  productId: number,
  moodleId: number,
  courseName: string
) => {
  const { userId, userIdImp } = userData;

  const variables = {
    userId,
    userIdImp,
    productId,
    courseName,
    code: moodleId,
  };

  const data = await callApiQl(
    gql`
      mutation createClonePearsonCourseByCourseIdAndProductIdAndCourseName(
        $userId: String!
        $userIdImp: String
        $code: Long!
        $productId: Long!
        $courseName: String!
      ) {
        createClonePearsonCourseByCourseIdAndProductIdAndCourseName(
          userId: $userId
          productId: $productId
          code: $code
          userIdImp: $userIdImp
          courseName: $courseName
        ) {
          code
          courseId
          createDate
          groupId
          isIndipendent
          name
          productId
        }
      }
    `,
    variables,
    false
  );
  return data;
};

export const activationLink = async (
  userData: UserDataForQuery,
  productId: number,
  accessLinkClickCount = 0,
  isActivationLinkClicked = true,
  isHelpLinkClicked = false
) => {
  const { userId, userIdImp } = userData;

  const variables = {
    userId,
    userIdImp,
    productId,
    accessLinkClickCount,
    isActivationLinkClicked,
    isHelpLinkClicked,
  };

  const data = await callApiQl(
    gql`
      mutation createPearsonActivationLinkProductIdAndUserId(
        $userId: String!
        $userIdImp: String
        $productId: Long!
        $accessLinkClickCount: Int!
        $isActivationLinkClicked: Boolean!
        $isHelpLinkClicked: Boolean!
      ) {
        createPearsonActivationLinkProductIdAndUserId(
          userId: $userId
          productId: $productId
          userIdImp: $userIdImp
          pearsonLinksProduct: {
            accessLinkClickCount: $accessLinkClickCount
            isActivationLinkClicked: $isActivationLinkClicked
            isHelpLinkClicked: $isHelpLinkClicked
          }
        ) {
          statusCode
          statusMessage
        }
      }
    `,
    variables,
    false
  );
  return data.createPearsonActivationLinkProductIdAndUserId;
};

export const enrollCourse = async (userData: UserDataForQuery, productId: number, code: number) => {
  const { userId, userIdImp, userRole } = userData;

  const variables = {
    userId,
    userIdImp,
    role: userRole,
    productId,
    code,
  };

  const data = await callApiQl(
    gql`
      mutation createPearsonSubscriptionCourseByCourseIdAndUserIdAndProductId(
        $userId: String!
        $userIdImp: String
        $code: Long!
        $productId: Long!
        $role: String!
      ) {
        createPearsonSubscriptionCourseByCourseIdAndUserIdAndProductId(
          userId: $userId
          userIdImp: $userIdImp
          productId: $productId
          code: $code
          role: $role
        ) {
          assetId
          messageResult

          errors {
            message
          }
        }
      }
    `,
    variables,
    false
  );
  if (data?.createPearsonSubscriptionCourseByCourseIdAndUserIdAndProductId?.errors?.[0]?.message) {
    throw new Error(
      data?.createPearsonSubscriptionCourseByCourseIdAndUserIdAndProductId.errors[0].message
    );
  }
  return data;
};

export const renameCourse = async (
  userData: UserDataForQuery,
  courseId: number,
  courseName: string
) => {
  const { userId, userIdImp } = userData;

  const variables = {
    userId,
    userIdImp,
    courseId,
    name: courseName,
  };

  const data = await callApiQl(
    gql`
      mutation pearsonCoursesByProductIdAndUserId(
        $userIdImp: String
        $userId: String!
        $courseId: Long!
        $name: String!
      ) {
        updatePearsonCourseByName(
          userId: $userId
          userIdImp: $userIdImp
          courseId: $courseId
          name: $name
        ) {
          code
          courseId
          createDate
          groupId
          isIndipendent
          name
          productId
        }
      }
    `,
    variables,
    false
  );
  return data;
};

export const deleteCourse = async (userData: UserDataForQuery, courseId: number) => {
  const { userId, userIdImp } = userData;

  const variables = {
    userId,
    userIdImp,
    courseId,
  };

  const data = await callApiQl(
    gql`
      mutation deletePearsonCourseByCourseId(
        $userId: String!
        $userIdImp: String
        $courseId: Long!
      ) {
        deletePearsonCourseByCourseId(userId: $userId, userIdImp: $userIdImp, courseId: $courseId) {
          code
          courseId
          createDate
          groupId
          isIndipendent
          name
          productId
        }
      }
    `,
    variables,
    false
  );
  return data;
};
